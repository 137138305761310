.am-full-screen-dialog {
  height: 100vh;
  width: 100vw !important;
  max-width: none !important;
  .mat-dialog-container {
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }
}

.no-padding .mat-dialog-container {
  padding: 0;
  .mat-dialog-content,
  .mat-dialog-actions {
    margin: 0;
    padding-right: 16px;
  }
}

.confirmation-dialog {
  position: relative !important;
  z-index: 1000;
}