.mat-simple-snackbar-action {
  color: #2296c7;
}

.am-snackbar-error {
  color: white;
  background-color: #d50000;
  .mat-simple-snackbar {
    justify-content: center;
  }
}