.am-box-shadow-bottom {
  -moz-box-shadow: 0 3px 5px 0 #ccc;
  -webkit-box-shadow: 0 3px 5px 0 #ccc;
  box-shadow: 0 3px 5px 0 #ccc;
  z-index: 11;
}

.am-box-shadow-right {
  -moz-box-shadow: 8px 2px 16px -5px #ccc;
  -webkit-box-shadow: 8px 2px 16px -5px #ccc;
  box-shadow: 8px 2px 16px -5px #ccc;
  z-index: 11;
}

.am-overview {
  background-color: rgba(242, 242, 242, 1);
  padding: 24px;
}

.am-involvement-section {
  &:not(:nth-last-child(1)) {
    margin-bottom: 16px;
  }
}

.am-highlight-text {
  background: yellow !important;
}
