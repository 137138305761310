.mat-toolbar {
  background: rgb(250, 250, 250);
}

.am-page-toolbar {
  padding: 18px 24px 18px 18px;
}

.am-toolbar {
  padding: 8px;
  max-height: 56px;
  overflow: hidden;
  background: white;
}

#listingsearch {
  min-width: 100px;
  border-radius: 4px;
  padding: 5px;
  right: 10px;
  margin-bottom: 2px;
  position: relative;
  background: white;
  box-shadow: 0 .5px 1.5px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.12);


  >md-icon {
    margin: 0 10px;
  }

  input {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
  }

  &:hover {
    background: rgba(243, 243, 243, 0.550);
    box-shadow: 0 .5px 1.5px rgba(0, 0, 0, 0.18), 0 1px 1px rgba(0, 0, 0, 0.15);
  }
}