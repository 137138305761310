mat-card {
  &.am-information-card {
    padding: 16px;
    padding-top: 8px;
    .am-information-header {
      font-weight: 400;
      letter-spacing: 0.01em;
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .no-results-text {
      margin-top: 24px;
      padding-bottom: 24px;
    }
  }
  &.am-data-table-card {
    padding: 0;
    .am-information-header {
      font-weight: 400;
      letter-spacing: 0.01em;
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .no-results-text {
      margin-top: 24px;
      padding-bottom: 24px;
    }
    .am-toolbar {
      height: 56px;
      padding-left: 16px;
    }
    .mat-header-row {
      padding-left: 16px;
      .mat-header-cell:first-of-type {
        padding: 0;
      }
    }
    .mat-row {
      padding-left: 16px;
      .mat-cell:first-of-type {
        padding: 0;
      }
    }
    .am-text-emphasis-cell {
      font-family: Roboto;
      font-weight: 500;
    }
    .no-results {
      min-height: 56px;
      line-height: 56px;
      text-align: center;
    }
  }
}

.am-involvement-card {
  &:not(:nth-last-child(1)) {
    margin-bottom: 24px;
  }
}