.mat-form-field.wd-auto {
  .mat-form-field-infix {
    width: auto !important;
  }
}

.mat-form-field.wd-wide {
  width: 100%;
  .mat-form-field-infix {
    width: unset;
  }
  .mat-datepicker-toggle .mat-icon-button {
    right: 10px;
    top: -4px;
  }
}

.mat-form-field.bg-gray .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
  .mat-form-field-infix {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.mat-form-field.bg-accent .mat-form-field-flex {
  background-color: #ffe9cc;
}

.mat-form-field.mb0 .mat-form-field-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}

au-popover-filter-text {
  .mat-form-field-underline{
    display: none;
  }
}