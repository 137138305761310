body {
  overflow-x: hidden;
}

a.disabled {
  color: rgba(0, 0, 0, 0.48);
  pointer-events: none;
  cursor: default;
}

textarea.disabled:hover {
  outline: none;
}