@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
$am-dark-blue: (50: #eaeef2, 100: #d4dde5, 200: #a9bbcb, 300: #7e99b2, 400: #537798, 500: #28557e, 600: #154469, 700: #133c5c, 800: #0d2b43, 900: #091f31, A100: #86B6FF, A200: #5397FF, A400: #2078FF, A700: #0768FF, contrast: ( 50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $light-primary-text, 400: $light-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $light-primary-text, A400: $light-primary-text, A700: $light-primary-text));
$am-light-blue: (50: #e9f5fa, 100: #d3eaf4, 200: #a7d5e9, 300: #7ac0dd, 400: #4eabd2, 500: #2196F3, 600: #ae8cbd, 700: #1b7eaa, 800: #17ba8f, 900: #155f7f, A100: #D4ECFF, A200: #A1D5FF, A400: #6EBFFF, A700: #54B3FF, contrast: ( 50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $dark-primary-text, 400: $light-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $dark-primary-text, A400: $dark-primary-text, A700: $light-primary-text));
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$default-primary: mat-palette($am-light-blue);
$default-accent: mat-palette($mat-orange, A200, A100, A400);
// The warn palette is optional (defaults to red).
$default-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$default-theme: mat-light-theme($default-primary, $default-accent, $default-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($default-theme);
$dark-primary: mat-palette($am-dark-blue);
$dark-theme: mat-dark-theme($dark-primary, $default-accent, $default-warn);
.am-dark-theme {
  @include angular-material-theme($dark-theme);
  .mat-radio-outer-circle {
    border-color: mat-color($default-accent, A200); // this was added because the border-color was white
  }
}

@import './_mixins';
@import './sass/index';