.mat-header-cell {
  font-size: 13px;
  font-weight: normal;
}

.mat-row {
  &.details-row {
    background-color: #fafafa;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgba(0, 0, 0, 0.12);
  }
  &.interactive-table-row {
    cursor: pointer;
    position: relative;
    &:hover {
      background: #f5f5f5;
    }
    &:active {
      background: #eaeaea;
    }
  }
}

.mat-table-sticky,
.mat-header-row.sticky-header,
.mat-header-row.sticky-header th {
  z-index: 10 !important;
}

.mat-cell,
.mat-header-cell {
  // FUIE
  min-height: auto !important;
  &.checkbox {
    overflow: unset;
  }
}

.no-wrap {
  .mat-sort-header-button {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}